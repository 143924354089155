import { getAllProductsBySearch } from '@commerce/utils/operations'
import { Layout, SeoHead } from '@components/common'
import { client, findCategoryIds, prismicPreviewData } from '@lib/prismic'
import SliceZone from 'containers/SliceZone'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'

export async function getStaticProps({
  preview,
  locale,
  locales,
  previewData = {},
}: GetStaticPropsContext | prismicPreviewData) {
  const { ref } = previewData
  const config = { locale, locales }

  let homepage = null
  let announcementBar = null
  let navigationData = null
  let footerData = null
  let categoryProducts = null

  try {
    const results = await Promise.all([
      client().getSingle('announcement_bar'),
      client().getSingle('main_navigation'),
      client().getSingle('footer_navigation'),
    ])

    homepage =
      (await client().getByUID('general', `homepage`, ref ? { ref } : {})) || {}

    const productsListDoc: any =
      homepage && homepage?.data && homepage?.data?.body

    const productsListIds = findCategoryIds(productsListDoc)

    if (productsListIds.length > 0) {
      const categoryProductsQueries = productsListIds.map((id: string) =>
        getAllProductsBySearch(id)
      )

      categoryProducts = await Promise.all(categoryProductsQueries)
    }

    announcementBar = results[0]?.data
    navigationData = results[1].data
    footerData = results[2].data
  } catch (err) {
    console.log(err)
  }
  return {
    props: {
      announcementBar: announcementBar || null,
      homepage: homepage || null,
      navigationData: navigationData || null,
      footerData: footerData || null,
      categoryProducts: categoryProducts || null,
      categories: null,
      brands: null,
      pages: null,
      preview: preview || false,
    },
    revalidate: 60,
  }
}

export default function Home({
  homepage,
  categoryProducts,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { data: homepageData } = homepage || {}

  const { body, seo_title, seo_description, seo_opengraph_image } =
    homepageData || {}
  return (
    <>
      <SeoHead
        title={seo_title || ''}
        description={seo_description || ''}
        image={seo_opengraph_image}
      />
      <SliceZone slices={body || []} categoryProducts={categoryProducts} />
    </>
  )
}

Home.Layout = Layout
